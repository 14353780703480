<template>
  <img 
    :src="src"
    :title="title"
    :alt="alt"
    :class="{
        'hide': hide,
        'avatar': avatar
    }"
  />
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: true,
        },

        hide: {
            type: Boolean,
            required: false,
        },

         avatar: {
            type: Boolean,
            required: false,
        },

        title: {
            type: String,
        },

        alt: {
            type: String,
        }
    }
}
</script>


<style lang="scss" scoped>
img {
  max-width: 100%;
  height: auto;
}

.avatar {
  border-radius: 50%;
}

.half {
  @media(max-width: $mobile-bp) {
    max-width: 50%;
  }
}

.hide {
  @media(max-width: $mobile-bp) {
    display: none
  }
}

.small {
  max-width: 30%;
}
</style>

